import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core"
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms"
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router"
import { filter, map, takeUntil } from "rxjs/operators"
import { BehaviorSubject, Subject, Subscription } from "rxjs"
import { Location } from "@angular/common"
import { HttpClient } from "@angular/common/http"
import { BookEditComponent } from "../../book-edit.component"
import { TranslateService } from "@ngx-translate/core"
import { TecnoturisService } from "../../../tecnoturis.service"
import saveAs from "file-saver"
import {
  Client,
  BookingsRequest,
  PackagePax,
  ShoppingBasketPackage,
  PackagePaxEnum,
} from "../../../lib-shared/interfaces/index"
import {
  AuthenticationService,
  BasketManager,
  ClientServices,
  MicrositeService,
  NotificationPopupService,
  PackagesProviderService,
  SpinnerLoaderService,
} from "../../../lib-shared/services/index"
import { AgencyChangeService } from "../../../agency-change/index"
import { ROLES } from "../../../const"
import { CustomValidators } from "../../../lib-shared/validators/customValidators"
import * as moment_ from "moment"
import { COUNTRIES } from "../../../../mock/countries"
import { t } from "typy"
import { abbreviations, documentTypes } from "../../../../mock/flights"
import { PackageVacationPackageBooking } from "@vecib2c/frontend-dto"

const moment = moment_

@Component({
  selector: "lib-packages-book-edit",
  templateUrl: "./packages-book-edit.component.html",
  styleUrls: ["./packages-book-edit.component.scss"],
  providers: [
    PackagesProviderService,
    ClientServices,
    { provide: "user-host", useValue: "user" },
  ],
})
export class PackagesBookEditComponent
  extends BookEditComponent
  implements OnInit, OnDestroy {
  shoppingBasket: ShoppingBasketPackage
  form: FormGroup
  forms: FormGroup
  ltsForm: FormGroup
  formAgency: FormGroup
  bookingRequest: BookingsRequest = new BookingsRequest()
  abbreviations = abbreviations
  documentTypes = documentTypes
  countries = COUNTRIES
  newClient: boolean
  client: Client
  waivedInsurance = false
  acceptedConditions = false
  auth = AuthenticationService.getUser()
  total = 0
  uuidPre
  maxBirthdateForAdults = moment().subtract(25, "years").toDate()
  maxBirthdateForChildren = moment().subtract(2, "years").toDate()
  maxBirthdateForInfants = moment().toDate()
  minDocumentExpirationDate = moment().add(1, "years").toDate()
  roles = ROLES
  p = 1
  preBookResponse
  subscription: Subscription
  private midInsuranceToken: string
  midInsurance = []
  @Input() onlyResumeInfo = false
  @Input() microsite = false
  get adultsFormArray(): FormArray {
    return this.forms.controls["adults"] as FormArray
  }
  get childrenFormArray(): FormArray {
    return this.forms.controls["children"] as FormArray
  }
  get infantsFormArray(): FormArray {
    return this.form.controls["infants"] as FormArray
  }

  private unsubscribe$ = new Subject()
  protected micrositeBookingSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null)

  constructor(
    private activatedRoute: ActivatedRoute,
    public packageService: PackagesProviderService,
    private formBuilder: FormBuilder,
    private router: Router,
    private loaderService: SpinnerLoaderService,
    protected location: Location,
    protected translate: TranslateService,
    protected notification: NotificationPopupService,
    protected http: HttpClient,
    public _basketManager: BasketManager,
    protected clientServices: ClientServices,
    private route: ActivatedRoute,
    protected agencyChangeService: AgencyChangeService,
    private micrositeService: MicrositeService,
    public tecnoturisService: TecnoturisService,
  ) {
    super(
      location,
      http,
      clientServices,
      notification,
      translate,
      tecnoturisService,
      agencyChangeService
    )
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap
      .pipe(
        map(() => window.history.state),
        takeUntil(this._unsubscribe)
      )
      .subscribe(async (state) => {
        this.uuidPre = this.activatedRoute.snapshot.params["id"]
        this.midInsuranceToken =
          this.activatedRoute.snapshot.queryParams.midInsuranceToken
        const itemsFromStorage = await this._basketManager.getListPrebooking()
        this.shoppingBasket = itemsFromStorage.find(
          (item) => item.uuid === this.uuidPre
        )
        if (this.shoppingBasket) {
          this.total = this.shoppingBasket.totalAmount
          this.initializeForm()
          this.initializeClient()
        } else {
          if (state.package) {
            this.shoppingBasket = ShoppingBasketPackage.initialize(
              state.package
            )
            this.total = this.shoppingBasket.totalAmount
            this.initializeFormV2()
            this.initializeClient()
            if (!this.microsite) {
              this._basketManager.addPrebooking(this.shoppingBasket)
            } else {
              this._basketManager.addPrebookingMicrosites(this.shoppingBasket)
            }
          } else {
            const uuid = this.activatedRoute.snapshot.params["id"]
            const itemsFromStorage = await this._basketManager.getList()
            this.shoppingBasket = itemsFromStorage.find(
              (item) => item.uuid === uuid
            )
            if (this.shoppingBasket) {
              this.total = this.shoppingBasket.totalAmount
              this.initializeForm()
              this.initializeClient()
            }
          }
        }
      })
  }

  getNameTransfer(name, hotel): string {
    return name.replace("Hotel", "Hotel " + hotel.name + "-")
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
    if (!this.microsite) {
      this._basketManager.removePrebooking(this.uuidPre)
    }
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  redirectToSummary(): string {
    return this.microsite
      ? "/bookings/summary"
      : "/integration/bookings/book-summary"
  }

  downloadInsuranceWaiver(): void {
    if (!this.waivedInsurance) {
      const pdfUrl = "assets/files/pdf/renuncia-al-seguro.pdf"
      const pdfName = "renuncia-al-seguro.pdf"
      saveAs(pdfUrl, pdfName)
    }
  }

  book(): void {
    console.log("book")
    if (!super.checkClient()) {
      return
    }
    if (!this.acceptedConditions) {
      this.loaderService.show()

      const adults = this.adultsFormArray.value as PackagePax[]
      const children = this.childrenFormArray.value as PackagePax[]
      this.completeShoppingBasket(adults, children)

      this.InfoAgency()
      /*
      this._basketManager.addOrUpdate(this.shoppingBasket).then(() => {
        this._basketManager.getList().then((basket) => {
          if (
            basket.length === 1 &&
            basket[0].uuid === this.shoppingBasket.uuid
          ) {
            const bookingParameters = BookingsRequest.build(
              this.shoppingBasket,
              this.client
            )
            this.packageService
              .book(bookingParameters, true)
              .pipe(
                map((res) => res.data),
                takeUntil(this.unsubscribe$)
              )
              .subscribe(
                async (res) => {
                  if (res.error) {
                    this.navigateToConfirmationPage(null, null, true)
                  } else {
                    const listFromStorage =
                      await this._basketManager.addOrUpdate(this.shoppingBasket)
                    this.navigateToConfirmationPage(
                      this.shoppingBasket,
                      res,
                      false
                    )
                  }
                  this.loaderService.hide()
                },
                (err) => {
                  this.navigateToConfirmationPage(null, null, true)
                  this.loaderService.hide()
                }
              )
          } else {
            if (basket.length) {
              const path = this.redirectToSummary()
              this.router.navigateByUrl(path)
            } else {
              this.notificationPopupService.openPopup("EMPTY_BASKET")
            }
          }
        })
      })*/
    }
  }

  async addToBasketAndRedirect(redirectPath?: string): Promise<void> {
    if (super.checkClient()) {
      const adults = this.adultsFormArray.value as PackagePax[]
      const children = this.childrenFormArray.value as PackagePax[]
      this.completeShoppingBasket(adults, children)
      this.InfoAgency()
      await this._basketManager.addOrUpdate(this.shoppingBasket)
      const redirectToPath = !redirectPath
        ? this.redirectToPath()
        : redirectPath
      this.router.navigateByUrl(redirectToPath, { replaceUrl: true })
    }
  }

  private initializeForm(): void {
    this.forms = this.formBuilder.group({
      adults: this.formBuilder.array([]),
      children: this.formBuilder.array([]),
    })

    this.formAgency = this.formBuilder.group({
      expediente: [
        "",
        [
          Validators.required,
          Validators.minLength(13),
          Validators.maxLength(13),
          Validators.pattern("(^([0-9]{7,15})$)"),
        ],
      ],
      agentNum: ["", Validators.required],
    })

    let adults: PackagePax[] = []
    let children: PackagePax[] = []
    let infants: PackagePax[] = []

    if (this.shoppingBasket.paxes) {
      adults = this.shoppingBasket.paxes.filter((pax) => pax.type === "Adult")
      children = this.shoppingBasket.paxes.filter((pax) => pax.type === "Child")
      infants = this.shoppingBasket.paxes.filter((pax) => pax.type === "Infant")
    }

    for (let i = 0;i < this.shoppingBasket.adults;i++) {
      this.initializeFormByPaxType("adults", adults[i])
    }

    for (let i = 0;i < this.shoppingBasket.children;i++) {
      this.initializeFormByPaxType("children", children[i])
    }
  }

  private initializeFormV2(): void {
    this.forms = this.formBuilder.group({
      adults: this.formBuilder.array([]),
      children: this.formBuilder.array([]),
    })

    this.formAgency = this.formBuilder.group({
      expediente: [
        "",
        [
          Validators.required,
          Validators.minLength(13),
          Validators.maxLength(13),
          Validators.pattern("(^([0-9]{7,15})$)"),
        ],
      ],
      agentNum: ["", Validators.required],
    })

    for (const dist of this.shoppingBasket.distribution) {
      this.initializeFormByPaxTypeV2(dist)
    }
  }

  getFormData(): FormArray {
    return this.forms.get("passengers") as FormArray
  }

  private initializeFormByPaxTypeV2(pax: any): void {
    const adults = new Array(pax.adults).fill(undefined)
    adults.map((adult) => {
      ; (this.forms.controls["adults"] as FormArray).push(
        this.formBuilder.group({
          abbreviation: [
            t(pax, "abbreviation").safeString,
            Validators.required,
          ],
          name: [t(pax, "name").safeString, Validators.required],
          code: this.p++,
          ages: 25,
          lastname: [t(pax, "lastname").safeString, Validators.required],
          lastname2: [t(pax, "lastname2").safeString],
          phone: [t(pax, "phone").safeString, Validators.nullValidator],
          email: [t(pax, "email").safeString, Validators.nullValidator],
          documentType: [
            t(pax, "documentType").safeString || "PAS",
            Validators.required,
          ],
          documentNumber: [
            t(pax, "documentNumber").safeString,
            Validators.nullValidator,
          ],
          birthdate: [t(pax, "birthdate").safeString, [Validators.required]],
          // documentExpirationDate: [
          //   t(pax, "documentExpirationDate").safeString,
          //   [
          //     Validators.nullValidator,
          //     CustomValidators.minDate(this.minDocumentExpirationDate),
          //   ],
          // ],
          nationality: [
            t(pax, "nationality").safeString || "ES",
            Validators.required,
          ],
          phoneNumberCode: [
            t(pax, "phoneNumberCode").safeObject || 34,
            Validators.required,
          ],
          type: "Adult",
        })
      )
    })

    if (pax.children.length > 0) {
      pax.children.map((child) => {
        ; (this.forms.controls["children"] as FormArray).push(
          this.formBuilder.group({
            abbreviation: [
              t(pax, "abbreviation").safeString,
              Validators.required,
            ],
            name: [t(pax, "name").safeString, Validators.required],
            code: this.p++,
            ages: child,
            lastname: [t(pax, "lastname").safeString, Validators.required],
            lastname2: [t(pax, "lastname2").safeString],
            phone: [t(pax, "phone").safeString, Validators.nullValidator],
            email: [t(pax, "email").safeString, Validators.nullValidator],
            documentType: [
              t(pax, "documentType").safeString || "PAS",
              Validators.required,
            ],
            documentNumber: [
              t(pax, "documentNumber").safeString,
              Validators.nullValidator,
            ],
            birthdate: [
              t(pax, "birthdate").safeString,
              [
                Validators.required,
                CustomValidators.maxDates(child, this.shoppingBasket.checkIn),
              ],
            ],
            // documentExpirationDate: [
            //   t(pax, "documentExpirationDate").safeString,
            //   [
            //     Validators.nullValidator,
            //     CustomValidators.minDate(this.minDocumentExpirationDate),
            //   ],
            // ],
            nationality: [
              t(pax, "nationality").safeString || "ES",
              Validators.required,
            ],
            phoneNumberCode: [
              t(pax, "phoneNumberCode").safeObject || 34,
              Validators.required,
            ],
            type: "Child",
          })
        )
      })
    }
  }

  private initializeFormByPaxType(arrayName: string, pax: PackagePax): void {
    if (arrayName == "children") {
      ; (this.forms.controls[arrayName] as FormArray).push(
        this.formBuilder.group({
          abbreviation: [
            t(pax, "abbreviation").safeString,
            Validators.required,
          ],
          name: [t(pax, "name").safeString, Validators.required],
          code: [t(pax, "code").safeNumber, Validators.required],
          ages: [t(pax, "ages").safeNumber, Validators.required],
          lastname: [t(pax, "lastname").safeString, Validators.required],
          lastname2: [t(pax, "lastname2").safeString],
          phone: [t(pax, "phone").safeString, Validators.nullValidator],
          email: [t(pax, "email").safeString, Validators.nullValidator],
          documentType: [
            t(pax, "documentType").safeString || "PAS",
            Validators.required,
          ],
          documentNumber: [
            t(pax, "documentNumber").safeString,
            Validators.nullValidator,
          ],
          birthdate: [
            t(pax, "birthdate").safeString,
            [
              Validators.required,
              CustomValidators.maxDates(pax.ages, this.shoppingBasket.checkIn),
            ],
          ],
          // documentExpirationDate: [
          //   t(pax, "documentExpirationDate").safeString,
          //   [
          //     Validators.nullValidator,
          //     CustomValidators.minDate(this.minDocumentExpirationDate),
          //   ],
          // ],
          nationality: [
            t(pax, "nationality").safeString || "ES",
            Validators.required,
          ],
          phoneNumberCode: [
            t(pax, "phoneNumberCode").safeObject || 34,
            Validators.required,
          ],
          type: [PackagePaxEnum[arrayName]],
        })
      )
    } else {
      ; (this.forms.controls[arrayName] as FormArray).push(
        this.formBuilder.group({
          abbreviation: [
            t(pax, "abbreviation").safeString,
            Validators.required,
          ],
          name: [t(pax, "name").safeString, Validators.required],
          code: [t(pax, "code").safeNumber, Validators.required],
          ages: [t(pax, "ages").safeNumber, Validators.required],
          lastname: [t(pax, "lastname").safeString, Validators.required],
          lastname2: [t(pax, "lastname2").safeString],
          phone: [t(pax, "phone").safeString, Validators.nullValidator],
          email: [t(pax, "email").safeString, Validators.nullValidator],
          documentType: [
            t(pax, "documentType").safeString || "PAS",
            Validators.required,
          ],
          documentNumber: [
            t(pax, "documentNumber").safeString,
            Validators.nullValidator,
          ],
          birthdate: [t(pax, "birthdate").safeString, [Validators.required]],
          // documentExpirationDate: [
          //   t(pax, "documentExpirationDate").safeString,
          //   [
          //     Validators.nullValidator,
          //     CustomValidators.minDate(this.minDocumentExpirationDate),
          //   ],
          // ],
          nationality: [
            t(pax, "nationality").safeString || "ES",
            Validators.required,
          ],
          phoneNumberCode: [
            t(pax, "phoneNumberCode").safeObject || 34,
            Validators.required,
          ],
          type: [PackagePaxEnum[arrayName]],
        })
      )
    }
  }

  private initializeClient(): void {
    this.clientServices.subscription
      .pipe(
        filter(
          (client) =>
            client && !(Object.keys(client).length === 0) && client.isPax
        )
      )
      .subscribe((client: Client) => {
        if (client && !(Object.keys(client).length === 0)) {
          const paxesControl = this.forms.controls["adults"] as FormArray
          paxesControl.at(0).get("name").setValue(client.name)
          paxesControl.at(0).get("lastname").setValue(client.surname)
          paxesControl.at(0).get("email").setValue(client.email)
          paxesControl.at(0).get("documentNumber").setValue(client.document)
          paxesControl.at(0).get("phone").setValue(client.phoneNumber)
          paxesControl.at(0).get("type").setValue("Adults")
        }
      })
  }

  private completeShoppingBasket(
    adults: PackagePax[],
    children: PackagePax[]
  ): void {
    this.shoppingBasket.paxes = [...adults, ...children]
  }

  private InfoAgency(): void {
    this.shoppingBasket.agencyInfo = this.formAgency.value
  }

  private navigateToConfirmationPage(
    shoppingBasket: ShoppingBasketPackage,
    bookingPostResDto: PackageVacationPackageBooking,
    failed: boolean
  ): void {
    const navigationExtras: NavigationExtras = {}

    if (failed) {
      navigationExtras.state = {
        bookingPostResDto: [{ failed: true }],
      }
    } else {
      navigationExtras.state = {
        bookingPostResDto: [bookingPostResDto],
        shoppingBasket,
      }
    }
    this.router.navigate(
      ["/integration/bookings/book-confirmation"],
      navigationExtras
    )
  }
  showDocument(document: string): void {
    window.open(document, "_blank")
  }
}
