import { Pipe, PipeTransform } from '@angular/core';
import { ShopingBasketHotel, ShoppingBasketFlight } from '../../integrations/hotels/index';
import { ShopingBasketInsurance } from '../../integrations/insurances/index';
import {
    IBookingItem,
    integrationTypes,
    ShoppingBasketPackage,
    ShoppingBasketActivity,
} from '../interfaces/index';
import { ShoppingBasketHFS } from '../../integrations/hotels/dto/ShoppingBasketHFS';
import { ShoppingBasketMultidestination } from '../interfaces/shopping-basket-multidestination.model';
import { ShoppingBasketTransfer } from '../interfaces/shopping-basket-transfers.model';

@Pipe({
    name: 'checkBasketItemType'
})
export class CheckBasketItemTypePipe implements PipeTransform {

    transform(item: IBookingItem): string {
        const basketHotelCheck = (item: any): item is ShopingBasketHotel => {
            if ((item as ShopingBasketHotel).hotelGetReq && (item as ShopingBasketHotel).bookingPostReqDto) {
                return true;
            }
            return false;
        };

        const basketHFCheck = (item: any): item is ShoppingBasketHFS => {
            if ((item as ShoppingBasketHFS).hfs && (item as ShoppingBasketHFS).clonedData) {
                return true;
            }
            return false;
        };

        const basketFlightCheck = (item: any): item is ShoppingBasketFlight => {
            if ((item as ShoppingBasketFlight).requestId
                &&
                (item as ShoppingBasketFlight).priceGroupId
                &&
                (item as ShoppingBasketFlight).itinerariesId) {
                return true;
            }

            return false;
        };

        const basketInsuranceCheck = (item: any): item is ShopingBasketInsurance => {
            if ((item as ShopingBasketInsurance).query
                &&
                (item as ShopingBasketInsurance).insuranceBookingPostReqDto
            ) {
                return true;
            }
            return false;
        };

        const basketPackageCheck = (item: any): item is ShoppingBasketPackage => {
            return (item as ShoppingBasketPackage).integrationType === 'PACKAGE';
        };

        const multidestinationCheck = (item): item is ShoppingBasketMultidestination => {
            return !!(item as ShoppingBasketMultidestination).locations;
        };

        const activityCheck = (item): item is ShoppingBasketActivity => {
            return (item as ShoppingBasketActivity).integrationType === 'ACTIVITY';
        };

        const transferCheck = (item): item is ShoppingBasketTransfer => {
            return !!(item as ShoppingBasketTransfer);
        };

        if (basketHotelCheck(item)) {
            return integrationTypes[0];
        }

        if (basketFlightCheck(item)) {
            return integrationTypes[1];
        }

        if (basketHFCheck(item)) {
            return integrationTypes[3];
        }

        if (basketInsuranceCheck(item)) {
            return integrationTypes[4];
        }

        if (basketPackageCheck(item)) {
            return integrationTypes[5];
        }

        if (multidestinationCheck(item)) {
            return integrationTypes[8];
        }

        if (activityCheck(item)) {
            return integrationTypes[9];
        }

        if (transferCheck(item)) {
            return integrationTypes[10];
        }

        throw new Error('Item isn`t a recognized basket item');
    }
}
