import { ShoppingBasketFlight } from "../../integrations/hotels/dto/ShoppingBasketFlight";
import { ShopingBasketHotel } from "../../integrations/hotels/dto/ShopingBasketHotel";
import {
  ShoppingBasketPackage,
  ShoppingBasketMultidestination,
  ShoppingBasketActivity,
} from "./index";
import { ShoppingBasketHFS } from "../../integrations/hotels/dto/ShoppingBasketHFS";
import { ShopingBasketInsurance } from "../../integrations/insurances/dto/ShopingBasketInsurance";
import { BudgetComment } from "./budget.interface";
import { BudgetPrice } from "./budget/budget-price.model";
import { ShoppingBasketTransfer } from './shopping-basket-transfers.model';

export type ShoppingBasketType =
  ShopingBasketHotel |
  ShoppingBasketHFS |
  ShoppingBasketPackage |
  ShoppingBasketMultidestination |
  ShoppingBasketActivity |
  ShoppingBasketFlight |
  ShopingBasketInsurance |
  ShoppingBasketTransfer;

export class ShoppingBasketByDestination {
  hotels?: ShopingBasketHotel[];
  flights?: ShoppingBasketFlight[];
  activities?: ShoppingBasketActivity[];
  destinationIndex: number;
}

export class ShoppingBasketComment implements BudgetComment, BudgetPrice {
  clientComment?: string;
  agentComment?: string;
  updatedPrice?: number;
  initialPrice?: number;

  static buildFromBudgetComment(
    comment: BudgetComment,
  ): ShoppingBasketComment {
    const { clientComment, agentComment } = comment;
    const basketComment: ShoppingBasketComment = {
        clientComment,
        agentComment,
    };
    return basketComment;
  }

  static buildFromPrice(
    price: number,
    initialPrice: number,
  ): ShoppingBasketComment {
    const basketComment: ShoppingBasketComment = {
        updatedPrice: price,
        initialPrice,
    };
    return basketComment;
  }
}

export function isHotelShoppingBasket(obj: ShoppingBasketType): obj is ShopingBasketHotel {
  return obj.integrationType === 'HOTEL';
}

export function isHFSShoppingBasket(obj: ShoppingBasketType): obj is ShoppingBasketHFS {
  return obj.integrationType === 'HF';
}

export function isPackageShoppingBasket(obj: ShoppingBasketType): obj is ShoppingBasketPackage {
  return obj.integrationType === 'PACKAGE';
}

export function isMultidestinationShoppingBasket(obj: ShoppingBasketType): obj is ShoppingBasketMultidestination {
  return obj.integrationType === 'MULTIDESTINATION';
}

export function isActivityShoppingBasket(obj: ShoppingBasketType): obj is ShoppingBasketActivity {
  return obj.integrationType === 'ACTIVITY';
}

export function isFlightShoppingBasket(obj: ShoppingBasketType): obj is ShoppingBasketFlight {
  return obj.integrationType === 'FLIGHT';
}

export function isInsuranceShoppingBasket(obj: ShoppingBasketType): obj is ShopingBasketInsurance {
  return obj.integrationType === 'INSURANCE';
}

export function isTransferShoppingBasket(obj: ShoppingBasketType): obj is ShoppingBasketTransfer {
  return obj.integrationType === 'TRANSFER';
}
