import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterComponent } from './filter.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [FilterComponent],
    imports: [
        CommonModule,
        MatCheckboxModule,
        IonicModule,
        FormsModule,
        MatInputModule,
        MatAutocompleteModule,
        TranslateModule

    ],
    exports: [FilterComponent]
})
export class FilterModule {
}
