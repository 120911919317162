
import { Client } from "./client.model";
import { Transfers, Occupancy, Extras } from './transfers/response/transfer.response.dto';
import { BookingRequestTransfer, PassengerTransfer } from './transfers/request/booking-create.request.dto';
import { IntegrationType } from './integration.types';
import { ShoppingBasketComment } from './shopping-basket.model';
import { safeNumber } from '../functions/utils';
import { ComissionsTransfers, FromPreBooking, ManagementPax, ToPreBooking, TransferServicesBooked } from "..";
import { TransferPaxFromManagementFactory } from "../factories/transfers/transfer-pax-from-management.factory";

//IMPLEMENTAR CLIENT!

export class ShoppingBasketTransfer  {
    id: number | string;
    uuid: string;
    locator: string;
    sessionID: string;
    provider: string;
    currencyID: string;
    totalPrice?: number = 0;
    totalNet?: string | number;
    commission?: ComissionsTransfers;
    integrationType: IntegrationType;
    paxes: PassengerTransfer[];
    occupancy: Occupancy;
    from: FromPreBooking;
    to: ToPreBooking;
    transfer?: TransferServicesBooked
    transferType?: number;
    requestFromBasket: BookingRequestTransfer
    client: Client;
    validateMessage?: string;
    checkForm?: boolean;
    reratedPrice?: number;
    originalPrice?: number;
    failed?: boolean;
    isSelected?: boolean;
    canBeBooked?: boolean;
    comments?: ShoppingBasketComment
    // extras?: Extras[]
    // totalExtrasPrice?: number = 0;   ***W2M SOLO TIENE LOS EXTRAS EN EL ENTORNO DE PRUEBAS. 
    //revisar shoppingbasket trrenes por tema tokes, agentes etc etc


    static initializeComments(
        item: ShoppingBasketTransfer,
    ): ShoppingBasketTransfer {
        item.comments = {
            clientComment: '',
            agentComment: '',
            updatedPrice: safeNumber(item.totalPrice),
        };

        return item;
    }

    static updateFromManagementBudget(
        item: ShoppingBasketTransfer,
        paxes: ManagementPax[],
    ): ShoppingBasketTransfer {
        const mappedPaxes = TransferPaxFromManagementFactory.build(paxes, item.paxes);
        return {
            ...item,
            paxes: mappedPaxes,
        };
    }
}
