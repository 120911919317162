import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthenticationService, MicrositeService } from '../../projects/tecnoturis/src/public-api'
import { AgencyService } from './shared/service/agency.service';
import { Title } from '@angular/platform-browser';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, takeUntil } from 'rxjs/operators';
import * as moment from 'moment/moment';
import { Subject } from 'rxjs';
import { IAgency } from '@vecib2c/frontend-dto/dist/dto/Activities/Booking/common';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

    @HostListener('window:focus', ['$event'])
    onFocus(event: FocusEvent): void {
        this.agencyService.carouselAutoPlaySubject.next(true);
    }

    @HostListener('window:blur', ['$event'])
    onBlur(event: FocusEvent): void {
        this.agencyService.carouselAutoPlaySubject.next(false);
    }

    unsubscribe = new Subject();
    agency: IAgency;

    head: string
    constructor(
        private micrositeService: MicrositeService,
        private agencyService: AgencyService,
        private titleService: Title,
        private bpo: BreakpointObserver,
        public readonly route: ActivatedRoute,
        private translate: TranslateService,
        private authenticationService: AuthenticationService,
    ) {
        this.micrositeService.initMicrosite();
        this.authenticationService.isAuthenticatedSubject().subscribe(auth => {
            if (auth) {
                const authInfo: any = AuthenticationService.getUser();
                // this.router.navigate(['clients', 'edit', authInfo.client]);
            }
        });

        this.agencyService.agencySubject
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(value => {
                if (value) {
                    const root = document.documentElement;

                    root.style.setProperty('--text-colorc', value.textColor || value.base.textColor);
                    root.style.setProperty('--bg-colorc', value.mainColor || value.base.mainColor);
                    root.style.setProperty('--bg-color-transc', value.transparencyColor || value.base.transparencyColor);
                }
            });
    }

    async ngOnInit() {

        this.titleService.setTitle(window.location.hostname.split('.').shift());
        /*
        this.route.queryParams.subscribe(params => {
        const lang = params['lang'];
        if (lang && ['en', 'es', 'pt'].includes(lang)) {
            this.translate.use(lang);
            }
        }); */
        this.translate.use("pt");
        moment.updateLocale('es', {
            week: {
                dow: 1,
            },
        });

        const breakpoints = Object.keys(Breakpoints).map(key => Breakpoints[key]);
        this.bpo.observe(breakpoints)
            .pipe(map(bst => bst.matches))
            .subscribe(matched => {
                if (this.isExtraSmallDevice()) {
                    return;
                } else if (this.isSmallDevice()) {
                    return;
                } else if (this.isMediumDevice()) {
                    return;
                } else if (this.isLargeDevice()) {
                    return;
                }
            });
    }

    public isExtraSmallDevice(): boolean {
        this.agencyService.screenBreakpointsSubject.next(Breakpoints.XSmall);
        return this.bpo.isMatched(Breakpoints.XSmall);
    }

    public isSmallDevice(): boolean {
        this.agencyService.screenBreakpointsSubject.next(Breakpoints.Small);
        return this.bpo.isMatched(Breakpoints.Small);
    }

    public isMediumDevice(): boolean {
        this.agencyService.screenBreakpointsSubject.next(Breakpoints.Medium);
        return this.bpo.isMatched(Breakpoints.Medium);
    }

    public isLargeDevice(): boolean {
        this.agencyService.screenBreakpointsSubject.next(Breakpoints.Large);
        return this.bpo.isMatched(Breakpoints.Large);
    }
}