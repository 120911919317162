import { Pipe, PipeTransform } from "@angular/core";
import { SelectedService, ServiceType } from '../../interfaces/index';

@Pipe({
    name: 'serviceToLabel'
})
export class ServiceToLabelPipe implements PipeTransform {
  transform(service: SelectedService, type: ServiceType = 'FLIGHT'): string {
    if (!service) { return; }
    if (type === 'HOTEL') { return `${service.hotel.name}`; }
    if (type === 'FLIGHT') {
      const origin = service.originCity || service.origin;
      const destination = service.destinationCity || service.destination;
      return `${origin} - ${destination}`;
    }
  }
}
