import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { PackageHotel } from "@vecib2c/frontend-dto"

@Component({
  selector: "lib-detail-room",
  templateUrl: "./detail-room.component.html",
  styleUrls: ["./detail-room.component.scss"],
})
export class DetailRoomComponent implements OnInit {
  constructor() {}

  @Input() hotel: PackageHotel
  @Input() contractedOptions
  @Input() total: number
  @Input() microsites: boolean
  @Output() actionBooking = new EventEmitter()
  ngOnInit() {}
  getHotelSelected(rooms) {
    return rooms.filter((room) => room.selected)
  }
  filterRoomsFacilities(facilities) {
    return facilities.filter(
      (facility) => facility.groupId === "ROOM" || facility.groupId === "HOTEL"
    )
  }
  getPriceTotal(): string {
    let total = 0
    this.hotel.rooms.forEach((room) => {
      if (room.selected) {
        room.rates.forEach((rate) => {
          total += +rate.price
        })
      }
    })
    return total.toFixed(2)
  }
  handleReservation(roomId, isReservation = false) {
    this.actionBooking.emit({ roomId: roomId, isReservation: isReservation })
  }
}
