import { CustomError } from './custom-error.model';
import {
  HotelListResDto,
  FlightListResDto,
  HotelListReqDto,
  PriceGroup,
  Hotel,
  ActivitySearchGetResDto,
  ActivityDetailGetResDto,
  IActivitySearchResponse,
  HotelFilter,
} from '@vecib2c/frontend-dto';
import { SearcherParameter } from './searcher-parameter.model';
import { ActivityDetailWithPassengers, PassengerAmount } from '../activities/activity-detail.model';
import * as moment_ from 'moment';
import { IFilter } from '../filter.interface';
import { HotelFilterMapper } from '../hotel/hotel-filter';
import { FlightFilter } from '../flight/flight-filter';

const moment = moment_;

/* tslint:disable */
export type ServiceType = 'HOTEL' | 'FLIGHT' | 'ACCOMMODATION' | 'TRANSPORT' | 'ACTIVITY' | 'ACCOMMODATION_ACTIVITY' | 'ACCOMMODATION_ACTIVITY_CAR' | 'ALL';

export enum ServiceTypeEnum {
  'HOTEL' = 'Alojamiento',
  'FLIGHT' = 'Vuelo',
}

export class MultidestinationHotelFilter extends HotelFilter {
  radius: number;
  hotelName?: string;

  static build(filter: IFilter, hotelName?: string) {
    const multidestinationHotelFilter: MultidestinationHotelFilter = HotelFilterMapper.build(filter);

    if (filter.radius) {
      multidestinationHotelFilter.radius = filter.radius;
    }

    if (hotelName) {
      multidestinationHotelFilter.hotelName = hotelName;
    }

    return multidestinationHotelFilter;
  }
}

export class MultidestinationFlightFilter extends FlightFilter {
}

export class MultidestinationTrainFilter {
  price: { minPrice: number, maxPrice: number };
}

export class MultidestinationActivityFilter {
  price?: { lower: number, upper: number };
  categories?: string[];
  durations?: string[];
}

export class MultidestinationCarFilter {
  minPrice?: number;
  maxPrice?: number;
  products?: string[];
  franchiseConditions?: string[];
  suppliers?: string[];
  seats?: number[];
  doors?: number[];
  airConditioners?: string[];
  transmissions?: string[];
  types?: string[];
  sizes?: string[];
}

export class MultidestinationDistribution {
  adults: number;
  children?: number[];
}

export class MultidestinationList {
  origins: string[];
  checkIns: string[];
  checkOuts: string[];
  distributions: MultidestinationDistribution[];
  destinations: string[];
  onlyDeparture: boolean;
  services: ServiceType[];
  hotelPages: number[];
  flightPages: number[];
  trainPages: number[];
  activityPages: number[];
  carPages: number[];
  hotelPageSizes: number[];
  flightPageSizes: number[];
  trainPageSizes: number[];
  activityPageSizes: number[];
  carPageSizes: number[];
  hotelFilters?: MultidestinationHotelFilter[];
  flightFilters?: MultidestinationFlightFilter[];
  trainFilters?: MultidestinationTrainFilter[];
  activityFilters?: MultidestinationActivityFilter[];
  carFilters?: MultidestinationCarFilter[];
  carRequestIds?: string[];
  requestToken?: string;

  static build(searcherParameter: SearcherParameter): MultidestinationList {
    const multidestinationList = new MultidestinationList();

    const {
      origins,
      destinations,
      checkIns,
      checkOuts,
      hotelPages,
      flightPages,
      trainPages,
      activityPages,
      carPages,
      hotelPageSizes,
      flightPageSizes,
      trainPageSizes,
      activityPageSizes,
      carPageSizes,
      services,
    } = this.getPartialParameters(searcherParameter);

    multidestinationList.origins = origins;
    multidestinationList.destinations = destinations;
    multidestinationList.checkIns = checkIns;
    multidestinationList.checkOuts = checkOuts;
    multidestinationList.distributions = searcherParameter.paxes;
    multidestinationList.onlyDeparture = false;
    multidestinationList.services =  services;
    multidestinationList.hotelPages = hotelPages;
    multidestinationList.flightPages = flightPages;
    multidestinationList.trainPages = trainPages;
    multidestinationList.activityPages = activityPages;
    multidestinationList.carPages = carPages;
    multidestinationList.hotelPageSizes = hotelPageSizes;
    multidestinationList.flightPageSizes = flightPageSizes;
    multidestinationList.trainPageSizes = trainPageSizes;
    multidestinationList.activityPageSizes = activityPageSizes;
    multidestinationList.carPageSizes = carPageSizes;

    return multidestinationList;
  }

  static buildRecalculationParameters(
    listParameters: MultidestinationList,
    initialPosition: number,
    initialArrivalDate: string,
    requestToken: string,
  ): MultidestinationList {

    const initialVal: MultidestinationList = {
      distributions: [],
      onlyDeparture: false,
      services: [],
      origins: [],
      destinations: [],
      checkIns: [],
      checkOuts: [],
      hotelPages: [],
      flightPages: [],
      trainPages: [],
      activityPages: [],
      carPages: [],
      hotelPageSizes: [],
      flightPageSizes: [],
      trainPageSizes: [],
      activityPageSizes: [],
      carPageSizes: [],
    };

    return listParameters.destinations.reduce((acc, destination, index) => {
      if (index < initialPosition) { return acc; }

      acc.origins = [...acc.origins, listParameters.origins[index]];
      acc.destinations = [...acc.destinations, destination];
      const nights = listParameters.checkOuts[index] ?
        moment(listParameters.checkOuts[index]).diff(listParameters.checkIns[index], 'days') :
        null;
      if (initialPosition === index) {
        acc.checkIns = [...acc.checkIns, initialArrivalDate];
        acc.checkOuts = [...acc.checkOuts, moment(initialArrivalDate).add(nights, 'days').format('YYYY-MM-DD')];
        acc.services = [...acc.services, 'ACCOMMODATION_ACTIVITY_CAR'];
      } else {
        const checkIn = acc.checkOuts[acc.checkOuts.length - 1];
        const checkOut = nights ? moment(checkIn).add(nights, 'days').format('YYYY-MM-DD') : '';
        acc.checkIns = [...acc.checkIns, checkIn];
        acc.checkOuts = [...acc.checkOuts, checkOut];
        acc.services = [...acc.services, index < listParameters.destinations.length - 1 ? 'ALL' : 'TRANSPORT'];
      }

      acc.distributions = listParameters.distributions;
      acc.onlyDeparture = false;
      acc.hotelPages = [...acc.hotelPages, 1];
      acc.flightPages = [...acc.flightPages, 1];
      acc.trainPages = [...acc.trainPages, 1];
      acc.activityPages = [...acc.activityPages, 1];
      acc.carPages = [...acc.carPages, 1];
      acc.hotelPageSizes = [...acc.hotelPageSizes, 10];
      acc.flightPageSizes = [...acc.flightPageSizes, 10];
      acc.trainPageSizes = [...acc.trainPageSizes, 10];
      acc.activityPageSizes = [...acc.activityPageSizes, 10];
      acc.carPageSizes = [...acc.carPageSizes, 10];
      acc.requestToken = requestToken;

      return acc;
    }, initialVal);
  }

  private static getPartialParameters(
    searcherParameter: SearcherParameter
  ): Partial<MultidestinationList> {
    const initialCheckIn = moment(searcherParameter.origin.departureDate).format('YYYY-MM-DD');

    return [...searcherParameter.destinations, searcherParameter.finalDestination].reduce((acc, cur, i, array)  => {
      if (i === 0) {
        acc.origins = [...acc.origins, searcherParameter.origin.code];
        acc.checkIns = [...acc.checkIns, initialCheckIn];
        acc.checkOuts = [...acc.checkOuts, moment(initialCheckIn).add(cur.nights, 'days').format('YYYY-MM-DD')];
      } else {
        const checkIn = acc.checkOuts[i - 1];
        acc.origins = [...acc.origins, searcherParameter.destinations[i - 1].code];
        acc.checkIns = [...acc.checkIns, checkIn];
        acc.checkOuts = [...acc.checkOuts, cur.nights ?
            moment(checkIn).add(cur.nights, 'days').format('YYYY-MM-DD')
            : ''
        ];
      }
      acc.destinations = [...acc.destinations, cur.code];
      acc.hotelPages = [...acc.hotelPages, cur.nights ? 1 : undefined];
      acc.flightPages = [...acc.flightPages, 1];
      acc.trainPages = [...acc.trainPages, 1];
      acc.activityPages = [...acc.activityPages, 1];
      acc.carPages = [...acc.carPages, 1];
      acc.hotelPageSizes = [...acc.hotelPageSizes, 10];
      acc.flightPageSizes = [...acc.flightPageSizes, 10];
      acc.trainPageSizes = [...acc.trainPageSizes, 10];
      acc.activityPageSizes = [...acc.activityPageSizes, 10];
      acc.carPageSizes = [...acc.carPageSizes, 10];
      acc.services = [...acc.services, i < array.length - 1 ? 'ALL' : 'TRANSPORT'];
      return acc;
    }, {
      origins: [],
      destinations: [],
      checkIns: [],
      checkOuts: [],
      hotelPages: [],
      flightPages: [],
      trainPages: [],
      activityPages: [],
      carPages: [],
      hotelPageSizes: [],
      flightPageSizes: [],
      trainPageSizes: [],
      activityPageSizes: [],
      carPageSizes: [],
      services: [],
    });
  }
}

export class MultidestiantionListResponse {
  status: number;
  data: MultidestinationResponse;
}

export class MultidestinationResponse {
  locations: Location[];
  requestToken: string;
  providerToken: string;
  errors?: CustomError[];
}

export class Location {
  origin: string;
  originCity: string;
  originCountry: string;
  destination: string;
  destinationCity: string;
  destinationCountry: string;
  checkIn: string;
  checkOut: string;
  arrivalDate?: string;
  hotelResponse: HotelListResDto;
  flightResponse: FlightListResDto;
  activityResponse: ActivitySearchGetResDto;
  hotelRequest: HotelListReqDto;
  hotelServiceToken: string;
  flightServiceToken: string;
  trainServiceToken: string;
  activityServiceToken: string;

  static buildRequestAndResponseFromSelectedService(
    selectedService: SelectedService,
    location: Location,
  ): Location {
    if (!selectedService) { return { ...location }; }

    return {
      ...location,
    };
  }


}

export class SelectedService {
  flight: PriceGroup;
  hotel: Hotel;
  activities: MultidestinationActivityService[];
  trainRequestToken?: string;
  trainProviderToken?: string;
  flightServiceToken?: string;
  trainServiceToken?: string;
  hotelServiceToken?: string;
  origin: string;
  originCity: string;
  destination: string;
  destinationCity: string;
  // checkIn: string;
  // checkOut: string;
  hotelRequest: HotelListReqDto;
  accommodationTotalPrice: number;
  transportTotalPrice: number;
  transportCheckIn: string;
  transportArrivalDate: string;
  accommodationCheckIn: string;
  accommodationCheckOut: string;
  activityCheckIn: string;
  activityCheckOut: string;
  carCheckIn: string;
  carCheckOut: string;

  static isValid(selectedServices: SelectedService[]): boolean {
    const countErrors = selectedServices.reduce((acc, service, i) => {
      const isLast = i === selectedServices.length - 1;
      if (!service.flight && !service.hotel && !service.activities && !isLast) {
        return acc + 1;
      } else {
        return acc;
      }
    }, 0);

    return countErrors === 0;
  }
}

export class MultidestinationActivityService {
  detail: ActivityDetailGetResDto;
  activityServiceToken: string;
  passengerAmounts: PassengerAmount[];
  activity: IActivitySearchResponse;

  static build(
    activityDetailWithPassengers: ActivityDetailWithPassengers,
    activityServiceToken: string,
    activity: IActivitySearchResponse,
  ): MultidestinationActivityService {
    return {
      detail: activityDetailWithPassengers.activityDetail,
      activityServiceToken: activityServiceToken,
      passengerAmounts: activityDetailWithPassengers.passengerAmounts,
      activity,
    };
  }
}
