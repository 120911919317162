import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { TranslateService } from '@ngx-translate/core';
import * as moment_ from 'moment';

const moment = moment_;

enum Pax {
    adults = "Adulto",
    children = "Niño",
    infants = "Infante",
}

enum TranslatePax {
adults = "CARIBE.PACKAGES.BOOKING.BOOK_EDIT.PAXES.ADULTS",
children = "CARIBE.PACKAGES.BOOKING.BOOK_EDIT.PAXES.CHILDREN",
infants = "CARIBE.PACKAGES.BOOKING.BOOK_EDIT.PAXES.INFANTS",
}

@Component({
    selector: 'lib-flight-package-paxes',
    templateUrl: './flight-package-paxes.component.html',
    styleUrls: ['./flight-package-paxes.component.scss']
})
export class FlightPackagePaxesComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() kind: string;
    @Input() abbreviations: [];
    @Input() typeDocuments: [];
    @Input() maxBirthdate: Date;
    @Input() minDocumentExpirationDate: Date;
    @Input() countries: { [code: string]: string };
    @Input() returnDate: Date;

    pax: string;
    translatedPax: string

    emailBlurred: boolean = false;
    phoneBlurred: boolean = false;
    text1Blurred: boolean[] = [false];
    text2Blurred: boolean[] = [false];
    text3Blurred: boolean[] = [false];
    cpBlurred: boolean[] = [false];
    dniBlurred: boolean[] = [false];
    adultBirthDateBlurred: boolean[] = [false];
    childrenBirthDateBlurred: boolean[] = [false];

    constructor(
        private fb: FormBuilder,
        private translate: TranslateService 

    ) {
    }

    ngOnInit(): void {
        this.pax = Pax[this.kind];
        this.translatedPax = this.translate.instant(TranslatePax[this.kind]);

    }

    onPhoneBlur(): void {
        this.phoneBlurred = true;
    }

    onEmailBlur(): void {
        this.emailBlurred = true;
    }

    onText1Blur(i: number): void {
        this.text1Blurred[i] = true;
    }

    onText2Blur(i: number): void {
        this.text2Blurred[i] = true;
    }

    onText3Blur(i: number): void {
        this.text3Blurred[i] = true;
    }

    onCpBlur(i: number): void {
        this.cpBlurred[i] = true;
    }
    
    validateDNISpanish(dni: string): boolean {
        const dniRegex = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/;
    
        if (!dniRegex.test(dni)) {
            return false;
        }
    
        const dniNumber = parseInt(dni.substring(0, 8), 10);
        const dniLetter = dni.substring(8, 9);
        const letters = 'TRWAGMYFPDXBNJZSQVHLCKE';
        const correctLetter = letters.charAt(dniNumber % 23);
        return dniLetter === correctLetter;
    }

    onDocumentNumberBlur(i: number): void {
        const typeDoc = this.getFormData.controls[i].get('documentType').value;
        if (typeDoc === "DNI") {
            const documentNumberControl = this.getFormData.controls[i].get('documentNumber').value;
            if (documentNumberControl) {
                const isValidDNI = this.validateDNISpanish(documentNumberControl);
                if (!isValidDNI) {
                    this.dniBlurred[i] = true;
                }
                else {
                    this.dniBlurred[i] = false;
                }
            }else {
                this.dniBlurred[i] = true;
            }
        }
        else
            this.dniBlurred[i] = false;

    }

    get getFormData(): FormArray {
        return <FormArray>this.form.get(this.kind);
    }

    setBirthdate(i: number, date: Date): void {
        const formattedDate = this.formatDate(date);
        this.getFormData.controls[i].get('birthdate').setValue(formattedDate);
        this.getFormData.controls[i].get('birthdate').markAsTouched();

        let birthdateSplited: string[] = formattedDate.split("/");
        let birthdateDay: number = parseInt(birthdateSplited[0]);
        let birthdateMonth: number = parseInt(birthdateSplited[1]) - 1;
        let birthdateYear: number = parseInt(birthdateSplited[2]);

        let today: Date = new Date(this.returnDate);
        let birthdate: Date = new Date(birthdateYear, birthdateMonth, birthdateDay);

        const time_dif: number = today.getTime() - birthdate.getTime();
        const miliSecondToYears: number = 1000 * 3600 * 24 * 365.25;
        const edad: number = time_dif / miliSecondToYears;
        if (this.pax != "Niño") {
            if (Math.floor(edad) < 18)
                this.adultBirthDateBlurred[i] = true;
            else
                this.adultBirthDateBlurred[i] = false;
        } else {
            if (Math.floor(edad) != this.getFormData.controls[i].get('ages').value)
                this.childrenBirthDateBlurred[i] = true;
            else
                this.childrenBirthDateBlurred[i] = false;
        }
    }

    setDocumentExpirationDate(i: number, date: Date): void {
        const formattedDate = this.formatDate(date);
        this.getFormData.controls[i].get('documentExpirationDate').setValue(formattedDate);
        this.getFormData.controls[i].get('documentExpirationDate').markAsTouched();
    }

    private formatDate(date: Date): string {
        return date ? moment(date).format('DD/MM/YYYY') : null;
    }
}
