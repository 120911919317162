import { FlightListResDto, HotelListResDto, ListAirBookBaggage } from "@vecib2c/frontend-dto";
import { encode, roundNumber, safeNumber } from "../functions/index";
import { IBookingItem } from "./bookingItem.interface";
import { Client } from "./client.model";
import {
    Luggage,
    MultidestinationPreBooking,
    MultidestinationPreBookingByLocation,
    MultidestinationPreBookingResponse,
} from "./multidestination/multidestination-pre-booking.model";
import * as  uuidv1_ from 'uuid/v1';
import { IntegrationType } from "./integration.types";
import { HotelComment, ShopingBasketHotel } from "../../integrations/hotels/dto/ShopingBasketHotel";
import { ShoppingBasketByDestination, ShoppingBasketComment } from "./shopping-basket.model";
import { HotelBookingPostReqOccupantMapper } from "./hotel/hotel-booking-post-req-occupant-mapper";
import { ShoppingBasketFlight } from "../../integrations/hotels/dto/ShoppingBasketFlight";
import { ShoppingBasketActivity } from "./shopping-basket-activity.model";
import { BudgetNote } from "./budget/budget-note.model";
import { SelectedService, Location } from "./multidestination/multidestination-list.model";
import { ActivityPreBookingResponse } from './multidestination/multidestination-pre-booking.model';
import { HotelListResMapper } from "./hotel/hotel-list-res-mapper";
import { FlightListMapper } from "./flight/flight-list-mapper.model";
import { ActivityDetailMapper } from "./activities/activity-detail.model";

const uuidv1 = uuidv1_;

export class ShoppingBasketMultidestination extends MultidestinationPreBookingResponse implements IBookingItem {
    uuid: string;
    client: Client;
    comments?: ShoppingBasketComment;
    validateMessage?: string;
    checkForm?: boolean;
    cabinLuggages?: Luggage[];
    totalPriceIncludingLuggage?: number;
    integrationType: IntegrationType;
    reratedPrice?: number;
    originalPrice?: number;
    failed?: boolean;
    isSelected?: boolean;
    canBeBooked?: boolean;
    budgetNotes?: BudgetNote[];

    public static generateUUID(preBookingParameters: MultidestinationPreBooking): string {
        const val = encodeURI(encode(JSON.stringify(
            {
                preBookingParameters,
                pageId: uuidv1(),
            }
        )));
        return val;
    }

    public static initialize(
        id: string,
        multidestinationPreBookingResponse: MultidestinationPreBookingResponse,
        selectedServices: SelectedService[] = [],
    ): ShoppingBasketMultidestination {
        const obj = this.buildFromPreBookingResponse(multidestinationPreBookingResponse, id, null, selectedServices);
        return obj;
    }

    public static buildLuggages(
        shoppingBasket: ShoppingBasketMultidestination
    ): { luggagesByPax: ListAirBookBaggage[][], luggagesByBooking: ListAirBookBaggage[][]} {

        let luggagesByPax: ListAirBookBaggage[][] = [];
        let luggagesByBooking: ListAirBookBaggage[][] = [];

        shoppingBasket.preBookingResponses.forEach(preBookingResponse => {
            if (preBookingResponse.lowCostFlightResponse) {
                luggagesByPax = [...luggagesByPax, preBookingResponse.lowCostFlightResponse.listAirBookBaggages.filter(l => l.byPax)];
                luggagesByBooking = [...luggagesByBooking, preBookingResponse.lowCostFlightResponse.listAirBookBaggages.filter(l => !l.byPax)];
            } else {
                luggagesByPax = [...luggagesByPax, []];
                luggagesByBooking = [...luggagesByBooking, []];
            }
        });
        const obj = { luggagesByPax, luggagesByBooking };
        return obj;
    }

    public static buildFromPreBookingResponse(
        preBookingResponse: MultidestinationPreBookingResponse,
        uuid: string,
        client: Client,
        selectedServices: SelectedService[],
    ): ShoppingBasketMultidestination {
        const mappedLocations = preBookingResponse.locations.map((location, i) => {
            const mappedResponse = Location.buildRequestAndResponseFromSelectedService(selectedServices[i], location);
            return mappedResponse;
        });
        const mappedPreBookingLocations = preBookingResponse.preBookingResponses.map((location, i) => {
            const mappedResponse = MultidestinationPreBookingByLocation.buildResponseFromSelectedService(selectedServices[i], location, i + 1);
            return mappedResponse;
        });
        const price = this.calculateTotalPrice(mappedLocations, mappedPreBookingLocations);

        const obj = {
            ...preBookingResponse,
            locations: mappedLocations,
            preBookingResponses: mappedPreBookingLocations,
            uuid,
            client,
            comments: {
                clientComment: '',
                agentComment: '',
                updatedPrice: price,
                initialPrice: price,
            },
            cabinLuggages: [],
            totalPriceIncludingLuggage: preBookingResponse.totalPrice,
            integrationType: 'MULTIDESTINATION',
        } as ShoppingBasketMultidestination;
        return obj;
    }

    public static calculateTotalPrice(
        locations: Location[],
        preBookingResponses: MultidestinationPreBookingByLocation[],
    ): number {
        const totalPrice = locations.reduce((acc, cur, index) => {

            const activityPreBookings = preBookingResponses[index] ? preBookingResponses[index].activityResponses : [];

            const accommodationPrice = this.calculateAccommodationPrice(cur.hotelResponse);
            const transportPrice = this.calculateTransportPrice(cur.flightResponse);
            const activityPrice = this.calculateActivtyTotalPrice(activityPreBookings);

            return acc + accommodationPrice + transportPrice + activityPrice ;
        }, 0);

        return roundNumber(totalPrice);
    }

    public static splitShoppingBasketsByDestination(
        shoppingBasket: ShoppingBasketMultidestination,
    ): ShoppingBasketByDestination[] {
        const { client, comments, passengers, preBookingResponses } = shoppingBasket;
        const hotelComments = HotelComment.buildFromShoppingBasketComment(comments);
        const hotelBookingOccupants = HotelBookingPostReqOccupantMapper.buildFromPassengers(passengers);

        const arr = shoppingBasket.locations.map((location, index) => {
            const hotels = ShopingBasketHotel.buildFromShoppingBasketByDestination(
                location,
                client,
                hotelComments,
                hotelBookingOccupants,
                preBookingResponses[index],
            );

            const flights = ShoppingBasketFlight.buildFromShoppingBasketByDestination(
                location,
                client,
                passengers,
                comments,
                preBookingResponses[index],
                index + 1,
            );

            const activities = ShoppingBasketActivity.buildFromShoppingBasketByDestination(
                client,
                comments,
                preBookingResponses[index],
            );

            const passengerAndDriver = passengers.find(p => {
                return p.drivingDestinations && p.drivingDestinations.some(d => d.destinationIndex === index + 1);
            });

            return {
                hotels,
                flights,
                activities,
                destinationIndex: index + 1,
            };
        });
        return arr;
    }

    static initializeComments(
        item: ShoppingBasketMultidestination,
    ): ShoppingBasketMultidestination {
        const price = safeNumber(item.totalPriceIncludingLuggage);
        item.comments = {
            clientComment: '',
            agentComment: '',
            updatedPrice: price,
            initialPrice: price,
        };

        return item;
    }

    private static calculateAccommodationPrice(hotelResponse: HotelListResDto): number {
        const val = hotelResponse ? this.calculateHotelTotalPrice(hotelResponse) : 0;
        return val;
    }

    private static calculateHotelTotalPrice(hotelResponse: HotelListResDto): number {
        return HotelListResMapper.calculatePVP(hotelResponse);
    }

    private static calculateTransportPrice(
        flightResponse: FlightListResDto,
    ) {
        let transportPrice = 0;
        if (flightResponse && flightResponse.count > 0)
            transportPrice = this.calculateFlightPrice(flightResponse);

        return transportPrice;
    }

    private static calculateFlightPrice(
        flightResponse: FlightListResDto,
    ): number {
        return FlightListMapper.calculatePVP(flightResponse);
    }

    private static calculateActivtyTotalPrice(
        activityResponses: ActivityPreBookingResponse[],
    ): number {
        return ActivityDetailMapper.calculatePVP(activityResponses);
    }

}
