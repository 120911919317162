import { Component } from "@angular/core";
import { VeciService } from "../../service/veci.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  htmlFooter: string = "";

  constructor(private veciService: VeciService) {
    if (document.getElementById("veciHeader").innerHTML !== "") {
      this.veciService.getHtml().subscribe((response: any) => {
        this.htmlFooter = response.results[0].footer;
      });
    }
  }
}
