import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
    AirlineCompanyNamePipe,
    AirlineLogoPipe,
    AirportsToCitiesPipe,
    AllAirportsPipe,
    CarsProviderLogoPipe,
    ChartremovePipe,
    ChangeTextToArrayPipe,
    CheapestProviderPipe,
    CheckBasketItemTypePipe,
    CheckConfirmationTypePipe,
    DiffTimePipe,
    DistanceMeters,
    FilterByFieldPipe,
    FilterImagesToShowPipe,
    FirstLetterUpperCasePipe,
    GetCurrencyPipe,
    GetDecimalPartPipe,
    GetIntegerPartPipe,
    GetIntegerPartPricePipe,
    GetDecimalPartPricePipe,
    GetNameByIdPipe,
    GetNumberPhonePipe,
    HtmlremovePipe,
    HumanizeBooleanPipe,
    MomentDatePipe,
    PaymentMethodIdToNamePipe,
    PaymentOptionIdToDescriptionPipe,
    PaymentOptionToDescriptionPipe,
    RoudingDecimalsPipe,
    SafeObjectPipe,
    SafePipe,
    SortByFieldPipe,
    TabIdToNamePipe,
    ValidationMessagePipe,
    FilterRoomByMultidistributionPipe,
    ServiceToLabelPipe,
    NumberOfStarsPipe,
    DisplayPassengerTypeForMultidestination,
    TransformPassengerTypeToConditionDate,
    PaxTypePipe,
    IncludesPipe,
    ToDatePipe,
    CheckLogoChainPipe,
    CleanSpacesPipe,
    NumberSymbolPipe,
    DifferentialAmountPipe,
    FilterArraySelectedPipe,
    DisplayLuggageOptionByDestinationPipe,
    DisplayWaitingTimePipe,
    CleanImagePipe,
    ReplaceHttpsPipe,
    ClearHotelPhotoUrlPipe,
    ExcludeParentServiceFromDossierPipe,
    BookingServiceIdToDescriptionPipe,
    FetchHotelShoppingBasketsByDestination,
    FetchFlightShoppingBasketsByDestination,
    SafeNumberPipe,
} from './pipes/index';
import { PaxToFlightPaxPipe } from '../searches/flights/paxToFlightPax.pipe';
import { Digit2OnlyDirective, HideTopScroll2Directive, ScrollTopButton2Directive } from './directives/index';
import { MomentTimePipe } from './pipes/momentTime.pipe';

@NgModule({
    declarations: [
        AllAirportsPipe,
        GetNameByIdPipe,
        SortByFieldPipe,
        TabIdToNamePipe,
        HumanizeBooleanPipe,
        SafeObjectPipe,
        GetNumberPhonePipe,
        GetCurrencyPipe,
        GetIntegerPartPipe,
        GetDecimalPartPipe,
        GetIntegerPartPricePipe,
        GetDecimalPartPricePipe,
        FirstLetterUpperCasePipe,
        CheapestProviderPipe,
        CheckBasketItemTypePipe,
        DistanceMeters,
        AirlineLogoPipe,
        AirportsToCitiesPipe,
        MomentDatePipe,
        MomentTimePipe,
        PaxToFlightPaxPipe,
        FilterImagesToShowPipe,
        SafePipe,
        CarsProviderLogoPipe,
        HtmlremovePipe,
        ChartremovePipe,
        ChangeTextToArrayPipe,
        AirlineCompanyNamePipe,
        DiffTimePipe,
        FilterByFieldPipe,
        ScrollTopButton2Directive,
        HideTopScroll2Directive,
        Digit2OnlyDirective,
        CheckConfirmationTypePipe,
        RoudingDecimalsPipe,
        ValidationMessagePipe,
        PaymentMethodIdToNamePipe,
        PaymentOptionToDescriptionPipe,
        PaymentOptionIdToDescriptionPipe,
        CheckLogoChainPipe,
        FilterRoomByMultidistributionPipe,
        ServiceToLabelPipe,
        NumberOfStarsPipe,
        DisplayPassengerTypeForMultidestination,
        TransformPassengerTypeToConditionDate,
        PaxTypePipe,
        IncludesPipe,
        ToDatePipe,
        CleanSpacesPipe,
        NumberSymbolPipe,
        DifferentialAmountPipe,
        FilterArraySelectedPipe,
        DisplayLuggageOptionByDestinationPipe,
        DisplayWaitingTimePipe,
        CleanImagePipe,
        ReplaceHttpsPipe,
        ClearHotelPhotoUrlPipe,
        ExcludeParentServiceFromDossierPipe,
        BookingServiceIdToDescriptionPipe,
        FetchHotelShoppingBasketsByDestination,
        FetchFlightShoppingBasketsByDestination,
        SafeNumberPipe,
    ],
    imports: [
        CommonModule,
        TranslateModule
    ],
    exports: [
        CommonModule,
        TranslateModule,
        AllAirportsPipe,
        GetNameByIdPipe,
        SortByFieldPipe,
        TabIdToNamePipe,
        HumanizeBooleanPipe,
        SafeObjectPipe,
        GetNumberPhonePipe,
        GetCurrencyPipe,
        GetIntegerPartPipe,
        GetDecimalPartPipe,
        GetIntegerPartPricePipe,
        GetDecimalPartPricePipe,
        FirstLetterUpperCasePipe,
        CheapestProviderPipe,
        CheckBasketItemTypePipe,
        DistanceMeters,
        AirlineLogoPipe,
        AirportsToCitiesPipe,
        MomentDatePipe,
        MomentTimePipe,
        PaxToFlightPaxPipe,
        FilterImagesToShowPipe,
        SafePipe,
        CarsProviderLogoPipe,
        HtmlremovePipe,
        ChartremovePipe,
        ChangeTextToArrayPipe,
        AirlineCompanyNamePipe,
        DiffTimePipe,
        FilterByFieldPipe,
        ScrollTopButton2Directive,
        HideTopScroll2Directive,
        Digit2OnlyDirective,
        CheckConfirmationTypePipe,
        RoudingDecimalsPipe,
        ValidationMessagePipe,
        PaymentMethodIdToNamePipe,
        PaymentOptionToDescriptionPipe,
        PaymentOptionIdToDescriptionPipe,
        CheckLogoChainPipe,
        FilterRoomByMultidistributionPipe,
        ServiceToLabelPipe,
        NumberOfStarsPipe,
        DisplayPassengerTypeForMultidestination,
        TransformPassengerTypeToConditionDate,
        PaxTypePipe,
        IncludesPipe,
        ToDatePipe,
        CleanSpacesPipe,
        NumberSymbolPipe,
        DifferentialAmountPipe,
        FilterArraySelectedPipe,
        DisplayLuggageOptionByDestinationPipe,
        DisplayWaitingTimePipe,
        CleanImagePipe,
        ReplaceHttpsPipe,
        ClearHotelPhotoUrlPipe,
        ExcludeParentServiceFromDossierPipe,
        BookingServiceIdToDescriptionPipe,
        FetchHotelShoppingBasketsByDestination,
        FetchFlightShoppingBasketsByDestination,
        SafeNumberPipe,
    ]
})
export class LibSharedModule {
}
