import { SelectedService, Location } from "./multidestination-list.model";
import {
  ActivityDetailGetResDto,
  HotelRoomList,
  HotelRoomRate,
  IOperationDate,
  IRateDetail,
  IRate,
  PrebookResDto,
  IActivityModality,
  ICancellationPolicy,
  ITotalAmount,
} from "@vecib2c/frontend-dto";
import { CustomError, ErrorType } from "./custom-error.model";
import { PassengerAmount } from "../activities/activity-detail.model";
import { AgencyCommission } from '../agency-commission.model';
import { ICommission } from '@vecib2c/frontend-dto/dist/dto/Activities/Common';


export class HotelPreBooking {
  hotelId: string;
  rooms: RoomPreBooking[];
  providerName: string;
  serviceToken: string;
  destinationIndex: number;
}

export class RoomPreBooking {
  roomName: string;
  roomId: string;
  rateName: string;
  rateCategory: string;
  rateId: string;
}

export class FlightPreBooking {
  priceGroupId: string;
  itineraryId: string;
  serviceToken: string;
  destinationIndex: number;
}




// @dynamic
export class MultidestinationPreBooking {
  hotels: HotelPreBooking[];
  flights: FlightPreBooking[];
  requestToken: string;

  constructor() {
    this.hotels = [];
    this.flights = [];
  }

  static build(selectedServices: SelectedService[], requestToken: string): MultidestinationPreBooking {
    const preBookingParameters = new MultidestinationPreBooking();

    selectedServices.forEach((selectedService, index) => {
      if (selectedService.hotel)  {
        preBookingParameters.hotels.push({
          hotelId: selectedService.hotel.hotelId,
          rooms: this.buildRooms(selectedService.hotel.roomList),
          providerName: selectedService.hotel.providers[0].providerName,
          serviceToken: selectedService.hotelServiceToken,
          destinationIndex: index + 1,
        });
      }

      if (selectedService.flight) {
        preBookingParameters.flights.push({
          priceGroupId: selectedService.flight.id + '',
          itineraryId: selectedService.flight.itineraries[0].id + '',
          serviceToken: selectedService.flightServiceToken,
          destinationIndex: index + 1,
        });
      }

    });

    preBookingParameters.requestToken = requestToken;

    return preBookingParameters;
  }

  // @dynamic
  static buildRooms(roomList: HotelRoomList[]): RoomPreBooking[] {
    const arr = roomList.map(list => {
      return {
        roomId: list.rooms[0].roomId,
        roomName: list.rooms[0].name,
        rateCategory: list.rooms[0].rates[0].category,
        rateName: list.rooms[0].rates[0].name,
        rateId: list.rooms[0].rates[0].rateId,
      };
    });
    return arr;
  }

}

export class MultidestinationPreBookingCreateResponse {
  status: number;
  data: MultidestinationPreBookingResponse;
}

export class MultidestinationPreBookingResponse {
  preBookingResponses: MultidestinationPreBookingByLocation[];
  locations: Location[];
  requestToken: string;
  preBookingToken: string;
  errors?: CustomError[];
  passengers: MultidestinationPassenger[];
  totalPrice: number;
}

export class MultidestinationPassenger {
  type: PassengerType;
  name: string;
  lastname: string;
  birthdate: string;
  abbreviation: AbbreviationType;
  phoneNumberCode: string;
  phoneType: string;
  phone: string;
  email: string;
  documentType: DocumentType;
  documentNumber: string;
  documentExpirationDate: string;
  nationality: string;
  roomNumber: number;
  luggages?: Luggage[];
  drivingDestinations?: MultidestinationDrivingDestination[];

  static completePassengerFromLocations(
      rawPassengers: MultidestinationPassenger[],
      locations: Location[]
  ): MultidestinationPassenger[] {
    const arr = rawPassengers.map(passenger => {
      const rawDrivingDestination = passenger.drivingDestinations;
      return {
        ...passenger,
        drivingDestinations: MultidestinationDrivingDestination.completeDrivingDestination(rawDrivingDestination, locations),
      };
    });
    return arr;
  }
}

export class MultidestinationDrivingDestination {
  additionalParameters: string[];
  dropOffDate: string;
  dropOffLocationID: number;
  dropOffTime: string;
  dropOffLocationCountry: string;
  dropOffLocationName: string;
  name: string;
  pickUpDate: string;
  pickUpLocationID: number;
  pickUpTime: string;
  pickUpLocationCountry: string;
  pickUpLocationName: string;
  productID: number;
  rateQualifier: string;
  supplierCode: string;
  supplierName: string;
  totalCharge: number;
  urlImage: string;
  uuid: string;
  destinationIndex: number;

  static completeDrivingDestination(
      rawDrivingDestination: MultidestinationDrivingDestination[],
      locations: Location[]
  ): MultidestinationDrivingDestination[] {

    const arr = rawDrivingDestination.map(drivingDestination => {
      const { destinationIndex } = drivingDestination;
      const location = locations[destinationIndex - 1];
      if (!location) { return { ...drivingDestination }; }
      return {
        ...drivingDestination,
      };
    });
    return arr;
  }
}

export type PassengerType = 'Adult' | 'Child' | 'Infant';

export type AbbreviationType = 'Mr' | 'Mrs' | 'Ms';

export type DocumentType = 'DNI' | 'NIE' | 'PAS';

export class MultidestinationPreBookingByLocation {
  origin: string;
  destination: string;
  hotelResponse?: HotelPreBookingResponse[];
  lowCostFlightResponse?: FlightPreBookingResponse;
  activityResponses?: ActivityPreBookingResponse[];
  hotelErrorResponses?: HotelErrorResponse[];
  lowCostFlightErrorResponses?: FlightErrorResponse[];
  checkIn: string;
  checkOut: string;
  arrivalDate: string;

  static buildResponseFromSelectedService(
      selectedService: SelectedService,
      location: MultidestinationPreBookingByLocation,
      destinationIndex: number,
  ): MultidestinationPreBookingByLocation {
    if (!selectedService) { return { ...location }; }

    const activityResponses = this.buildActivityResponseFromSelectedService(
        selectedService,
        location.activityResponses,
        destinationIndex
    );
    const obj = {
      ...location,
      activityResponses,
    };
    return obj;
  }

  static buildActivityResponseFromSelectedService(
      selectedService: SelectedService,
      responses: ActivityPreBookingResponse[],
      destinationIndex: number,
  ): ActivityPreBookingResponse[] {
    const { activities } = selectedService;
    if (!activities || activities.length === 0) { return []; }
    const arr = activities.map(activitiyService => {
      const { detail, passengerAmounts } = activitiyService;
      const response = responses.find(r => r.content.activityCode === detail.content.activityCode);
      if (response) { return { ...response }; }

      const passengerAges = PassengerAmount.buildActivityPassengerAges(passengerAmounts);
      const countPassenger = passengerAges.length;
      const modality = detail.modalities[0];
      const rate = modality.rates[0];
      const rateDetail = rate.rateDetails[0];
      const operationDate = rateDetail.operationDates[0];
      const cancellationPolicy = operationDate.cancellationPolicies[0];
      const totalAmount = rateDetail.totalAmount;

      const netPrice = passengerAmounts.reduce((acc, passengerAmount) => {
        const { amount: { amount }, numberOfPassenger } = passengerAmount;
        return acc + (amount * numberOfPassenger);
      }, 0);
      const { commissionRate, fee, iva } = rateDetail.totalAmount.commission;
      const comission = AgencyCommission.calculateCommission(netPrice, commissionRate, iva);
      const activityCommission: ICommission  = {
        ...totalAmount.commission,
        commission: rateDetail.totalAmount.commission.commission * countPassenger,
        pvp: AgencyCommission.calculatePVPFromCalculatedCommission(netPrice, comission, fee),
      };

      const mappedCancellationPolicy: ICancellationPolicy = Object.assign({}, cancellationPolicy, {
        amount: activityCommission.pvp,
      });
      const mappedOperationDate: IOperationDate = Object.assign({}, operationDate, {
        cancellationPolicies: [mappedCancellationPolicy]
      });
      const mappedTotalAmount: ITotalAmount  = Object.assign({}, totalAmount, {
        amount: netPrice,
        commission: activityCommission,
      });
      const mappedRateDetail: IRateDetail = Object.assign({}, rateDetail, {
        operationDates: [mappedOperationDate],
        totalAmount: mappedTotalAmount,
      });
      const mappedRate: IRate = Object.assign({}, rate, {
        rateDetails: [mappedRateDetail]
      });
      const mappedModality: IActivityModality = Object.assign({}, modality, {
        rates: [mappedRate]
      });

      const mappedResponse: ActivityPreBookingResponse = {
        ...detail,
        modalities: [mappedModality],
        selectedPaxes: passengerAges,
        destinationIndex,
      };
      return mappedResponse;
    });
    return arr;
  }



}

export class HotelPreBookingResponse extends HotelRoomRate implements IBookingResponse {
  destinationIndex: number;
  roomIndex: number;
}

export class FlightPreBookingResponse extends PrebookResDto implements IBookingResponse {
  destinationIndex: number;
}

export class ActivityPreBookingResponse extends ActivityDetailGetResDto implements IBookingResponse {
  destinationIndex: number;
}

export interface IBookingResponse {
  destinationIndex: number;
}

export class Luggage {
  luggageNumberOption: number;
  luggageOption: string;
  destinationIndex: number;
  luggageId?: string;
  priceWithCommission?: number;
}

export class ErrorResponse {
  errorCode: ErrorType;
  destinationIndex: number;
}

export class HotelErrorResponse extends ErrorResponse {
  hotelId: string;
  hotelName: string;
  providerName: string;
  roomId: string;
  roomName: string;
  roomIndex: number;
  rateId: string;
  rateName: string;
}

export class FlightErrorResponse extends ErrorResponse {
  priceGroupId: number;
  itineraryIds: number[];
  requesdtId: number;
}

