import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LibSharedModule } from '../lib-shared/index';
import { ShoppingCartSummaryComponent } from './shopping-cart-summary.component';
import { HotelCartItemComponent } from './cart-items/hotel/hotel-cart-item.component';
import { FlightCartItemComponent } from './cart-items/flight/flight-cart-item.component';
import { InsuranceCartItemComponent } from './cart-items/insurance/insurance-cart-item.component';
import { PackageCartItemComponent } from './cart-items/package/package-cart-item/package-cart-item.component';
import { HotelFlightCartItemComponent } from './cart-items/hotel-flight/hotel-flight-cart-item.component';
import { MultidestinationCartItemComponent } from './cart-items/multidestination/multidestination-cart-item.component';
import { ActivityCartItemComponent } from './cart-items/activity/activity-cart-item.component';
import { TransferCartItemComponent } from './cart-items/transfers/transfer-cart-item.component';

@NgModule({
    declarations: [
        ShoppingCartSummaryComponent,
        HotelCartItemComponent,
        FlightCartItemComponent,
        InsuranceCartItemComponent,
        PackageCartItemComponent,
        HotelFlightCartItemComponent,
        MultidestinationCartItemComponent,
        ActivityCartItemComponent,
        TransferCartItemComponent,
    ],
    imports: [
        IonicModule,
        LibSharedModule, 
    ],
    exports: [
        ShoppingCartSummaryComponent
    ]
})
export class ShoppingCartSummaryModule {
}
