// Core
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
// Common
import { Router } from "@angular/router";
import { getCurrencySymbol, Location } from "@angular/common";
// Utils
import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
// Dto & Providers
import { packageParameters } from "../../../../mock/packages";
import { CarouselImagesComponent } from "../../../carousel-images/carousel-images.component";
import {
  PackagesProviderService,
  ShareService,
} from "../../../lib-shared/services/index";
import { TecnoturisService } from "../../../tecnoturis.service";
import { ItemPackageExpansionPanelComponent } from "./item-package-expansion-panel/item-package-expansion-panel.component";
import { PackageHotel } from "@vecib2c/frontend-dto";
import { ReplaceHttpsPipe } from "../../../lib-shared/pipes/index";

@Component({
  selector: "lib-item-package-card",
  templateUrl: "./item-package-card.component.html",
  styleUrls: ["./item-package-card.component.scss"],
  providers: [
    PackagesProviderService,
    ReplaceHttpsPipe,
    { provide: "user-host", useValue: "user" },
  ],
})
export class ItemPackageCardComponent implements OnInit {
  @Input() hotel: PackageHotel;

  @ViewChild(ItemPackageExpansionPanelComponent, { static: false })
  itemPackageExpansionPanelComponent: ItemPackageExpansionPanelComponent;
  @Input() microsites = false;
  @Input() packageCategory: string;
  @Input() passengerCount: number;

  language = "ES";
  dataOthersRooms: Array<any> = [];
  loading = true;
  displayRooms = false;
  displayMap = false;
  displayDescription = false;

  optionsRooms: string;
  moreInfo: string;
  map: string;
  hotelId: string;

  constructor(
    public location: Location,
    public dialog: MatDialog,
    public share: ShareService,
    public router: Router,
    public translate: TranslateService,
    public packageService: PackagesProviderService,
    private tecnoturisService: TecnoturisService,
    private readonly replaceHttpsPipe: ReplaceHttpsPipe
  ) {}

  ngOnInit() {
    this.optionsRooms = `optionsRooms${this.hotel.hotelId}`;
    this.moreInfo = `moreInfo${this.hotel.hotelId}`;
    this.map = `map${this.hotel.hotelId}`;
    this.hotelId = this.hotel.hotelId;
  }

  getProviderLogo(relativePath: string) {
    return this.tecnoturisService.config.providers.default + "/" + relativePath;
  }

  getCheapestProvider(providers): any {
    let provider;
    providers.forEach((element) => {
      if (element["cheapest"]) {
        provider = element;
      }
    });
    return provider;
  }

  getCategory(category): Array<Number> {
    if (category === undefined) {
      return;
    }
    const regexNumber = /\d+/;

    if (!category || !regexNumber.test(category.value)) {
      return;
    } else {
      const value = category.value.match(regexNumber)[0];
      const integerValue = parseInt(value, 10);
      return new Array(integerValue);
    }
  }

  // TODO Revisar si esto tiene sentido  ¿Solo se muestra un telefono???)
  getNumber(phones) {
    if (phones !== undefined && phones.length > 0 && phones[0].number) {
      return phones[0].number;
    } else {
      return "";
    }
  }

  getUrl(hotel, room?: any) {
    console.log(hotel);
    this.language = this.packageService.language;
    let requestToken: string = "";
    if (room) {
      requestToken = room.packageInformation.requestToken;
    } else {
      requestToken = this.hotel.rooms[0].packageInformation.requestToken;
    }
    const packageId = room
      ? room.packageInformation.packageId
      : this.hotel.rooms[0].packageInformation.packageId;

    const query = {};

    packageParameters.forEach((parameter) => {
      if (this.share.get(parameter)) {
        query[parameter] = this.share.get(parameter);
      }
    });

    query["roomId"] = this.getRoomId(room);
    query["roomName"] = this.getRoomName(room);

    query["price"] = this.getRoomPrice(room);

    if (hotel && hotel.hotelId) {
      query["hotelId"] = hotel.hotelId;
    }

    if (hotel && hotel.name) {
      query["hotelName"] = hotel.name;
    }

    if (hotel && hotel.idProduct) {
      query["idProduct"] = hotel.idProduct;
    }

    if (requestToken) {
      query["requestToken"] = requestToken;
    }

    if (hotel && hotel.providerCode) {
      query["providerCode"] = hotel.providerCode;
    }
    if (hotel && hotel.midInsuranceToken) {
      query["midInsuranceToken"] = hotel.midInsuranceToken;
    }
    if (this.microsites) {
      return this.router.navigate(["packages", "detail", packageId], {
        queryParams: query,
      });
    }

    return this.router.navigate(
      ["integration", "packages", "detail", packageId],
      { queryParams: query }
    );
  }

  getRoomId(room: any): string {
    return room && room.roomId ? room.roomId : this.hotel.rooms[0].roomId;
  }

  getRoomName(room: any): string {
    return room && room.name ? room.name : this.hotel.rooms[0].name;
  }

  getRoomPrice(room: any): string {
    return room && room.packageInformation ? room.packageInformation.totalPrice : this.hotel.rooms[0].packageInformation.totalPrice;
  }

  onLoad() {
    this.loading = false;
  }

  openDialog(hotel) {
    const photoArr = [];

    if (hotel.photos.length === 1) {
      this.checkIfImageExists(hotel.photos[0], (exists) => {
        if (exists) {
          const mappedPhoto = this.replaceHttpsPipe.transform(hotel.photos[0]);
          photoArr.push({
            small: mappedPhoto,
            medium: mappedPhoto,
            big: mappedPhoto,
          });
        }
        if (!exists) {
          photoArr.push({
            small: "assets/images/caribe-error.jpg",
            medium: "assets/images/caribe-error.jpg",
            big: "assets/images/caribe-error.jpg",
          });
        }
      });
    } else {
      hotel.photos.forEach((photo, index) => {
        const mappedPhoto = this.replaceHttpsPipe.transform(photo);

        if (index !== 0) {
          photoArr.push({
            small: mappedPhoto,
            medium: mappedPhoto,
            big: mappedPhoto,
          });
        }
      });
    }

    const dialogRef = this.dialog.open(CarouselImagesComponent, {
      panelClass: ["custom-dialog-container", "carrousel-paquete-contenedor"],
      width: "40%",
      hasBackdrop: true,
    });
    dialogRef.componentInstance.galleryImages = photoArr;
  }

  checkIfImageExists(url, callback) {
    const img = new Image();
    img.src = url;
    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };
      img.onerror = () => {
        callback(false);
      };
    }
  }

  getPricePackage(priceByRoom): string {
    return parseInt(priceByRoom, 10).toFixed(2);
  }

  validateImg(name: string): boolean {
    return PackagesProviderService.validateProvider(name);
  }

  expandPanel(panel: string): void {
    if (panel === "rooms") {
      this.displayRooms = !this.displayRooms;
      this.displayMap = false;
      this.displayDescription = false;
      if (this.displayRooms) this.scroll(this.optionsRooms);
      if (!this.displayRooms) this.scrollCard(this.hotelId);
    } else if (panel === "map") {
      this.displayRooms = false;
      this.displayMap = !this.displayMap;
      this.displayDescription = false;
      if (this.displayMap) this.scroll(this.map);
      if (!this.displayMap) this.scrollCard(this.hotelId);
    } else if (panel === "description") {
      this.displayRooms = false;
      this.displayMap = false;
      this.displayDescription = !this.displayDescription;
      if (this.displayDescription) this.scroll(this.moreInfo);
      if (!this.displayDescription) this.scrollCard(this.hotelId);
    }
  }

  scroll(id) {
    const panel = document.getElementById(id);
    panel.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  scrollCard(id) {
    const panel = document.getElementById(id);
    panel.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
}
