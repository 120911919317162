import { ManagementPax } from "../../interfaces/management.interface";
import {
    isHotelShoppingBasket,
    isFlightShoppingBasket,
    isPackageShoppingBasket,
    isActivityShoppingBasket,
    isInsuranceShoppingBasket,
    isHFSShoppingBasket,
    ShoppingBasketType,
    isTransferShoppingBasket
} from "../../interfaces/shopping-basket.model";
import { ShoppingBasketFlight } from "../../../integrations/hotels/dto/ShoppingBasketFlight";
import { ShopingBasketHotel } from "../../../integrations/hotels/dto/ShopingBasketHotel";
import {
  ShoppingBasketPackage,
  ShoppingBasketActivity,
  ShoppingBasketTransfer
} from "../../interfaces/index";
import { ShoppingBasketHFS } from "../../../integrations/hotels/dto/ShoppingBasketHFS";
import { ShopingBasketInsurance } from "../../../integrations/insurances/dto/ShopingBasketInsurance";

export class ShoppingBasketFactory {

    static updateFromManagementBudget(
      paxes: ManagementPax[],
      rawBasket: ShoppingBasketType,
    ) {
      if (isHotelShoppingBasket(rawBasket)) {
        const basket = ShopingBasketHotel.updateFromManagementBudget(rawBasket, paxes);
        return basket;
      }

      if (isFlightShoppingBasket(rawBasket)) {
        const basket = ShoppingBasketFlight.updateFromManagementBudget(rawBasket, paxes);
        return basket;
      }

      if (isPackageShoppingBasket(rawBasket)) {
        const basket = ShoppingBasketPackage.updateFromManagementBudget(rawBasket, paxes);
        return basket;
      }

      if (isActivityShoppingBasket(rawBasket)) {
        const basket = ShoppingBasketActivity.updateFromManagementBudget(rawBasket, paxes);
        return basket;
      }

      if (isInsuranceShoppingBasket(rawBasket)) {
        const basket = ShopingBasketInsurance.updateFromManagementBudget(rawBasket, paxes);
        return basket;
      }

      if (isHFSShoppingBasket(rawBasket)) {
        const basket = ShoppingBasketHFS.updateFromManagementBudget(rawBasket, paxes);
        return basket;
      }

      if (isTransferShoppingBasket(rawBasket)) {
        const basket = ShoppingBasketTransfer.updateFromManagementBudget(rawBasket, paxes);
        return basket;
      }

      return rawBasket;
    }
  }
