import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { HotelBookEditComponent } from "./hotels/book-edit/hotel-book-edit.component"
import { HotelBookSummaryComponent } from "./hotels/book-summary/hotel-book-summary.component"
import { HotelBookConfirmationComponent } from "./hotels/book-confirmation/hotel-book-confirmation.component"
import { FlightBookSummaryComponent } from "./flights/book-summary/flight-book-summary.component"
import { FlightBookEditComponent } from "./flights/book-edit/flight-book-edit.component"
import { FlightBookConfirmationComponent } from "./flights/book-confirmation/flight-book-confirmation.component"
import { FailedBookingDialogComponent } from "./failed/failed-bookings-dialog.component"
import { HotelWrapperBookEdit } from "./hotels/book-edit-wrapper/hotel-book-edit-wrapper.component"
import { InsurancesBookConfirmationComponent } from "./insurances/book-confirmation/insurances-book-confirmation.component"
import { InsurancesBookEditComponent } from "./insurances/book-edit/insurances-book-edit.component"
import { InsurancesBookSummaryComponent } from "./insurances/book-summary/insurances-book-summary.component"
import { PackagesBookConfirmationComponent } from "./packages/book-confirmation/packages-book-confirmation.component"
import { PackagesBookEditComponent } from "./packages/book-edit/packages-book-edit.component"
import { PackagesBookSummaryComponent } from "./packages/book-summary/packages-book-summary.component"
import { HotelFlightBookEditComponent } from "./hotel-flight/book-edit/hf-book-edit.component"
import { HotelFlightWrapperBookEdit } from "./hotel-flight/book-edit-wrapper/hf-book-edit-wrapper.component"
import { HFBookSummaryComponent } from "./hotel-flight/book-summary/hf-book-summary.component"
import { HFBookConfirmationComponent } from "./hotel-flight/book-confirmation/hf-book-confirmation.component"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { IonicModule } from "@ionic/angular"
import {
  ScrollTopButtonModule,
  StepsLineModule,
} from "../integrations/shared/index"
import { FlightsIntegrationModule } from "../integrations/flights/index"
import { DialogModule } from "../dialog/index"
import { PackageSearchModule } from "../searches/packages/index"
import { PackagesModule } from "../integrations/packages/index"
import { MessagesModule } from "../messages/messages.module"
import { DateAdapterModule, LibSharedModule } from "../lib-shared/index"
import { ClientAutocompleteModule } from "../client-autocomplete/client-autocomplete.module"
import { MatFormFieldModule } from "@angular/material/form-field"
import { MatCardModule } from "@angular/material/card"
import { MatIconModule } from "@angular/material/icon"
import { MatInputModule } from "@angular/material/input"
import { MatSelectModule } from "@angular/material/select"
import { MatCheckboxModule } from "@angular/material/checkbox"
import { MatDialogModule } from "@angular/material/dialog"
import { MatRadioModule } from "@angular/material/radio"
import { MultidestinationBookEditComponent } from "./multidestination/book-edit/multidestination-book-edit.component"
import { MaterialDatePickerModule } from "../material-date-picker/material-date-picker.module"
import { MultidestinationBookSummaryComponent } from "./multidestination/book-summary/multidestination-book-summary.component"
import { MultidestinationServicesSummaryModule } from "../multidestination/services-summary/services-summary.module"
import { ActivityBookEditComponent } from "./activities/book-edit/activity-book-edit.component"
import { ActivityBookConfirmationComponent } from "./activities/book-confirmation/activity-book-confirmation.component"
import { ActivityBookSummaryComponent } from "./activities/book-summary/activity-book-summary.component"
import { LuggageSelectModule } from "../luggage-select/index"
import { MultidestinationBookingErrorModalComponent } from "./multidestination/book-confirmation/booking-error-modal/booking-error-modal.component"
import { InvalidPriceAlertModule } from "./alert/invalid-price-alert/invalid-price-alert.module"
import { InvalidAlertModule } from "./alert/invalid-alert/invalid-alert.module"
import { ReratedPriceModule } from "./rerated-price/rerated-price.module"
import { TransferBookEditComponent } from "./transfers/book-edit/transfer-book-edit.component"
import { TransferBookSummaryComponent } from "./transfers/book-summary/transfer-book-summary.component"
import { TransferBookConfirmationComponent } from "./transfers/book-confirmation/transfer-book-confirmation.component"
import { MultidestinationBookingForm } from "./multidestination/booking-form/multidestination-booking-form.component"
import { FooterModule } from "../../../../../src/app/shared/components/footer/footer.module"

@NgModule({
  declarations: [
    HotelBookEditComponent,
    HotelBookSummaryComponent,
    HotelBookConfirmationComponent,
    FlightBookSummaryComponent,
    FlightBookSummaryComponent,
    FlightBookEditComponent,
    FlightBookConfirmationComponent,
    FailedBookingDialogComponent,
    HotelWrapperBookEdit,
    InsurancesBookConfirmationComponent,
    InsurancesBookEditComponent,
    InsurancesBookSummaryComponent,
    FailedBookingDialogComponent,
    PackagesBookEditComponent,
    PackagesBookSummaryComponent,
    HotelFlightBookEditComponent,
    HotelFlightWrapperBookEdit,
    HFBookSummaryComponent,
    HFBookConfirmationComponent,
    MultidestinationBookEditComponent,
    MultidestinationBookSummaryComponent,
    ActivityBookEditComponent,
    ActivityBookConfirmationComponent,
    ActivityBookSummaryComponent,
    MultidestinationBookingErrorModalComponent,
    TransferBookEditComponent,
    TransferBookSummaryComponent,
    TransferBookConfirmationComponent,
    MultidestinationBookingForm,
    PackagesBookConfirmationComponent,

  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MessagesModule,
    FormsModule,
    IonicModule,
    MatFormFieldModule,
    StepsLineModule,
    ScrollTopButtonModule,
    FlightsIntegrationModule,
    DialogModule,
    PackageSearchModule,
    PackagesModule,
    MessagesModule,
    LibSharedModule,
    DateAdapterModule,
    ClientAutocompleteModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDialogModule,
    MatRadioModule,
    MaterialDatePickerModule,
    MultidestinationServicesSummaryModule,
    LuggageSelectModule,
    InvalidAlertModule,
    InvalidPriceAlertModule,
    ReratedPriceModule,
    FooterModule,
  ],
  exports: [
    HotelBookEditComponent,
    HotelBookSummaryComponent,
    HotelBookConfirmationComponent,
    FlightBookSummaryComponent,
    FlightBookSummaryComponent,
    FlightBookEditComponent,
    FlightBookConfirmationComponent,
    FailedBookingDialogComponent,
    HotelWrapperBookEdit,
    InsurancesBookConfirmationComponent,
    InsurancesBookEditComponent,
    InsurancesBookSummaryComponent,
    FailedBookingDialogComponent,
    PackagesBookEditComponent,
    PackagesBookSummaryComponent,
    HotelFlightBookEditComponent,
    HotelFlightWrapperBookEdit,
    HFBookSummaryComponent,
    HFBookConfirmationComponent,
    MessagesModule,
    MultidestinationBookEditComponent,
    MultidestinationBookSummaryComponent,
    ActivityBookEditComponent,
    ActivityBookConfirmationComponent,
    ActivityBookSummaryComponent,
    TransferBookEditComponent,
    TransferBookSummaryComponent,
    MultidestinationBookingForm,
    TransferBookConfirmationComponent,
    PackagesBookConfirmationComponent,

  ],
  entryComponents: [MultidestinationBookingErrorModalComponent],
})
export class BookingsModule {}
