import {Component, Input, OnInit} from '@angular/core';
import * as moment_ from 'moment';
import { TranslateService } from '@ngx-translate/core';

const moment = moment_;

@Component({
  selector: 'lib-detail-information',
  templateUrl: './detail-information.component.html',
  styleUrls: ['./detail-information.component.scss']
})
export class DetailInformationComponent implements OnInit {

  @Input() vacationPackage: any;
  @Input() hotel: any;
  @Input() infoPackage: any;

  distribution : any
  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.distribution = JSON.parse(this.infoPackage.distribution)
  }

  getCheckout(req) {
    const checkout = moment(req.checkIn, 'DD-MM-YYYY').add(+req.nights, 'days');
    return moment(checkout).format('DD-MM-YYYY');
  }

  getPhoneNumber(hotel: any): string {
    if (hotel && hotel.phones && hotel.phones.length > 0 && hotel.phones[0].number) {
      return hotel.phones[0].number;
    } else {
      return this.translate.instant('CARIBE.PACKAGES.DETAIL.INFORMATION.UNSPECIFIED');
    }
  }

}
